import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

export default function NoEntriesMessage({message}) {
  return (
    <div className='no-entries-message'>
      <FontAwesomeIcon className='no-entries-message-icon' icon={faMagnifyingGlass} />
      <p>{message}</p>
    </div>
  )
}
